import { logout } from 'src/hocs/AuthProvider';

import { useSelector } from 'src/store';
import {
  isLoggedInSelector,
  authUserNameSelector,
  authRoleReadableNameSelector,
} from 'src/store/selectors/authSelector';

import { UserNavbarMenu } from '@itm/shared-frontend/lib/components/layout';

function Header() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userName = useSelector(authUserNameSelector);
  const authRoleReadableName = useSelector(authRoleReadableNameSelector);

  return (
    <header className="has-background-white">
      <nav className="navbar is-flex is-justify-content-flex-end" role="navigation" aria-label="main navigation">
        {isLoggedIn && (
          <UserNavbarMenu userName={userName} authRoleReadableName={authRoleReadableName} logout={() => logout()} />
        )}
      </nav>
    </header>
  );
}

export default Header;
