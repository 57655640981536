import { ispApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';

import { TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import {
  CompanyResponse,
  CompanyConfigResponse,
  CompanyConfigurationsWithCount,
  UpdateCompanyConfigurationDto,
  AutoDeregisterPossibleMatchesModel,
} from 'src/types';

export const getCompanyListAll = (config: AxiosRequestConfig = {}) =>
  ispApi.get<CompanyResponse[]>('/isp-internal/api/v1/companies/all', config);

export const getCompanyById = (companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<CompanyConfigResponse>(`/isp-internal/api/v1/companies/${companyId}`, config);

export const getCompanyConfigurationListSearch = (
  params: TanstackTableSearchParamsAdapter,
  config: AxiosRequestConfig = {},
) => ispApi.get<CompanyConfigurationsWithCount>('/isp-internal/api/v1/companies/configurations', { ...config, params });

export const updateCompanyConfiguration = (data: UpdateCompanyConfigurationDto) =>
  ispApi.patch<void>('/isp-internal/api/v1/companies/configurations', data);

export const checkCompanyPdpPublishCredentials = (companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<void>(`/isp-internal/api/v1/companies/${companyId}/pdp/check`, config);

export const enableCompanyPDPPublish = (companyId: string) =>
  ispApi.patch<void>(`/isp-internal/api/v1/companies/${companyId}/pdp`);
export const disableCompanyPDPPublish = (companyId: string) =>
  ispApi.delete<void>(`/isp-internal/api/v1/companies/${companyId}/pdp`);

export const getChangesExtractIsEnabled = (companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<boolean>(`/isp-internal/api/v1/companies/${companyId}/changes-extract`, config);
export const enableChangesExtract = (companyId: string) =>
  ispApi.patch<void>(`/isp-internal/api/v1/companies/${companyId}/changes-extract`);
export const disableChangesExtract = (companyId: string) =>
  ispApi.delete<void>(`/isp-internal/api/v1/companies/${companyId}/changes-extract`);

export const enablePossibleMatchAutoDeregister = (companyId: string, data: AutoDeregisterPossibleMatchesModel) =>
  ispApi.patch<void>(`/isp-internal/api/v1/companies/${companyId}/auto-deregister-possible-matches`, data);
export const disablePossibleMatchAutoDeregister = (companyId: string) =>
  ispApi.delete<void>(`/isp-internal/api/v1/companies/${companyId}/auto-deregister-possible-matches`);

export const enableMatchesAutoRerun = (companyId: string) =>
  ispApi.patch<void>(`/isp-internal/api/v1/companies/${companyId}/matches-rerun`);
export const disableMatchesAutoRerun = (companyId: string) =>
  ispApi.delete<void>(`/isp-internal/api/v1/companies/${companyId}/matches-rerun`);
